import Container from './components/Container';
import Header from './components/Header';
import Testimonials from './components/Testimonials';
import Updates from './components/Updates';
import bg from './images/bg.jpg';
import './index.css';

function App() {
	return (
		<div>
			<Header></Header>
			<Container>
				<div className='main-content'>
					<div className='center-content'></div>
					{/* <button className="main-btn">Get Quote</button> */}
				</div>
				<img className='head-img' src={bg} alt='' />
				<Updates />

				<section className='contact-us' id='contact'>
					<div className='line'></div>
					<h2>Contacts</h2>
					<div className='contact-cards'>
						<div>
							<a href='tel:12403263224' className='main-btn'>
								Call
							</a>
							<p className='tel'>(240) 3263224</p>
						</div>
						<div>
							<h4>We are open on</h4>
							<ul className='table'>
								<li>Mon: 8:00 AM – 8:00 PM</li>
								<li>Tue: 8:00 AM – 8:00 PM</li>
								<li>Wed: 8:00 AM – 8:00 PM</li>
								<li>Thu: 8:00 AM – 8:00 PM</li>
								<li>Fri: 8:00 AM – 8:00 PM</li>
								<li>Sat: 8:00 AM – 8:00 PM</li>
								<li>Sun: 8:00 AM – 8:00 PM</li>
							</ul>
						</div>
					</div>
				</section>
			</Container>
			<footer>Eco Ducts &copy; {new Date().getFullYear()}</footer>
		</div>
	);
}

export default App;
