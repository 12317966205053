import React from 'react';
import img2 from '../images/2.jpg';
import img1 from '../images/1.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';

const Updates = () => {
	return (
		<section className='updates' id='updates'>
			<div className='line'></div>
			<h2>Services</h2>
			<div className='updates-cards'>
				<div className='updates_card'>
					<img src={img4} alt='' />
					<h4>$200 Chimney Inspection and Cleaning</h4>
					<ul className='updates_card-content'>
						<li>Detailed cleaning for chimney:</li>
						<li>Linear</li>
						<li>Crown</li>
						<li>Firebox</li>
						<li>Dumper</li>
					</ul>
				</div>
				<div className='updates_card'>
					<img src={img2} alt='' />
					<h4>$360 Air Duct Cleaning</h4>
					<ul className='updates_card-content'>
						<li>Complimentary deodorizing</li>
						<li>Cleaning all supply vents</li>
						<li>Filter check</li>
						<li>Furnace inspection</li>
						<li>No partial cleaning</li>
						<li>Cleaning Cold Air Return</li>
					</ul>
				</div>
				<div className='updates_card'>
					<img src={img1} alt='' />
					<h4>$179 Dryer Vent Cleaning</h4>

					<ul className='updates_card-content'>
						<li>Airflow effective test</li>
						<li>Dryer vent cleaning for 6 feet</li>
						<li>No partial cleaning</li>
						<li>Dryer unit and dryer vent inspection</li>
					</ul>
				</div>
				<div className='updates_card'>
					<img src={img3} alt='' />
					<h4>$395 Air Duct and Dryer Vent Cleaning</h4>
					<ul className='updates_card-content'>
						<li>Dryer unit and dryer vent inspection</li>
						<li>Furnace inspection</li>
						<li>Filter check</li>
						<li>No partial cleaning</li>
						<li>Cleaning all supply vents</li>
						<li>Complimentary deodorizing</li>
						<li>Dryer vent cleaning for 6 feet</li>
						<li>Airflow effective test</li>
						<li>Cleaning Cold Air Return</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Updates;
